// getIsResourceExisted.js
'use strict';
import fetch from '../resource/customFetch.js';
/**
 * Get is resource existed
 * @param {string} url - resource url.
 * @returns {boolean} return true when resource is existed.
 */
const getIsResourceExisted = async ({ url }) => {
  try {
    const response = await fetch(url, { method: 'GET' });

    if (response.ok) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

export default getIsResourceExisted;
